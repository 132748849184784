import * as React from "react";
import { Link } from '@mui/material';
import {
  AppBar,
  IconButton,
  List,
  ListItem,
  Stack,
  Toolbar
} from "@mui/material";
import styled from "@emotion/styled";
import ScrollspyNav from "react-scrollspy-nav";
import Container from "../MuiContainer";
import Logo from "../Logo";
import Socials from "../Socials";
import ContactSidebar from "../ContactSidebar";
import MenuSideBar from "../MenuSideBar";
import { Button } from '@mui/material';
import i18n from 'i18next';

function Header() {
  const [sticky, setSticky] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  return (
    <>
      <MuiAppBar
        position="fixed"
        elevation={0}
        className={`content ${sticky ? "sticky" : ""}`}
      >
        <TopBar sticky={sticky}>
          <Container maxWidth="xl">
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Link href="mailto:General@PivotalHubConsulting.com">
              <img src="/images/mail.svg" width="21" height="20" />
                General@PivotalHubConsulting.com
              </Link>
              <Socials />
            </Stack>
          </Container>
        </TopBar>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Logo sticky={sticky} />
            <ScrollspyNav
                scrollTargetIds={["about-us", "services", "faq", "contact-us"]}
                offset={-120}
                activeNavClass="is-active"
                scrollDuration="1000"
                headerBackground="true"
              >
                <ul>
                  <li><a href="#about-us">About Us</a></li>
                  <li><a href="#services">Services</a></li>
                  <li><a href="#faq">FAQ</a></li>
                  <li><a href="#contact-us">Contact Us</a></li>
                </ul>
              </ScrollspyNav>

            <Stack direction="row" spacing={{ xs: 1, sm: 2, lg: 7.5 }} alignItems="center">
              <Language sticky={sticky}>
                <Button variant="outlined" onClick={() => changeLanguage('en')}>
                  EN
                </Button>
                <Button variant="outlined" onClick={() => changeLanguage('pt')}>
                  PT
                </Button>
              </Language>
              <IconButton className="square-button" onClick={handleClick}>
              <img src="/images/square.svg" width="25" height="25" />
              </IconButton>
              <IconButton onClick={handleMenuClick} className="menu-bar"><img src="/images/bar.svg" width="24" height="17" /></IconButton>
            </Stack>
          </Toolbar>
        </Container>
      </MuiAppBar>
      <ContactSidebar open={open} setOpen={setOpen} handleClick={handleClick} />
      <MenuSideBar open={showMenu} setOpen={setShowMenu} handleClick={handleMenuClick} />
    </>
  );
}
export default Header;

const MuiAppBar = styled(AppBar)`
  ${({ theme }) => `
    &.MuiAppBar-root {
      background: transparent;

       @media (max-width: 900px) {
         background: ${theme.colors.palette.blue}60;

         ul {
           display: none !important;
         }
       }
    }

    &.sticky {
      animation: slideInDown 0.8s ease-out forwards;
      position: fixed;
      top: 0;
      background: ${theme.colors.palette.black};      

      .MuiToolbar-root ul li a {
       font-size: 16px;
      }      
    }

    .MuiLink-root{
      color: #FFF;
    }

    .menu-bar {
      display: none;
    }


    .MuiToolbar-root {
      padding: ${theme.spacing(1, 0)};
      justify-content: space-between;

       @media (max-width: 900px) {
         padding: 0;

         .square-button {
           display: none;
         }

         .menu-bar {
          display: block;
         }
       }

      ul {
        display: flex;
        gap: 60px;
       

       @media (max-width: 1400px) {
         gap: 16px;
       }

        li {
          text-transform: capitalize;
          white-space: nowrap;
          list-style: none; 

          a {
            font-size: 20px;
            transition: 0.3s ease all;
            color: inherit;
            text-decoration: none;


            @media (max-width: 1400px) {
              font-size: 16px;
            }

            &.is-active {
             font-weight: 700;
            }

            &:hover {
              color: ${theme.colors.palette.lightBlue};          
            }
          }
        }
      }
    }
  `}
`;

const TopBar = styled.div`
  ${({ theme, sticky }) => `
     background-color: ${theme.colors.palette.blue};
     padding: ${sticky ? theme.spacing(1, 0) : theme.spacing(1.875, 0)};
     transition: 0.3s ease all;

     @media (max-width: 900px) {
      display: none;
     }

     a {
       font-size: 12px;
       display: flex;
       align-items: center;
       gap: 12px;
     }
   `}
`;

const Language = styled.div`
  ${({ theme, sticky }) => `
    text-transform: uppercase;
    cursor: pointer;
    font-size: ${sticky ? "16px" : "20px"};

     @media (max-width: 1400px) {
        font-size: 16px;
      }

      .MuiButton-root {
        color: ${theme.colors.palette.white};
        border: 0;
        font-size: ${sticky ? "16px" : "20px"};
        padding: 0 10px;
        margin: 0 2px;
         min-width: auto;
        font-weight: 600;
        position: relative;

        &:not(:last-child)::after{
          content: "";
          width: 2px;
          height: 20px;
          background: ${theme.colors.palette.white};
          position: absolute;
          right: -2px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      @media (max-width: 900px) {
        font-size: 10px;
        border-radius: 5px;
        background: ${theme.colors.palette.blue};
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        .MuiButton-root {        
        font-size: ${sticky ? "10px" : "10px"};

        &:not(:last-child)::after{ height: 10px; }
       }
      }
   `}
`;
