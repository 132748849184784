import { createTheme } from "@mui/material";
import colors from "./colors";
import button from "./button";
import container from "./container";
import palette from "./palette";
import typography from "./typography";

const fontFamily = "'Poppins', 'sans-serif'";

const overrides = {
  colors,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
          fontSize: "14px"
        },
        outlined: {
          "&:hover": {
            background: "#2E3178",
            color: "#fff"
          }
        },
        text: {
          fontFamily: fontFamily,
          display: "none !important"
        }
      }
    },
    container
  },
  palette,
  typography
};

const Theme = createTheme(overrides);

export default Theme;
