import * as React from "react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import MuiContainer from "./MuiContainer";
import ChooseTile from "./tile/ChooseTile";
import MobileTitle from "./MobileTitle";
import { useTranslation } from 'react-i18next';

const WhyChooseUs = () => {

  const { t } = useTranslation();
  
  const WhyChooseData = [
    {
      title: t('whyChoose.strategicPlanning'),
      icon: "/images/choose-icon1.svg"
    },
    {
      title: t('whyChoose.operationalEfficiency'),
      icon: "/images/choose-icon2.svg"
    },
    {
      title: t('whyChoose.financialManagement'),
      icon: "/images/choose-icon3.svg"
    }
  ];


  return (
    <ChooseSection>
      <MuiContainer>
        <MobileTitle title={t('whyChoose.title')} />
        <Grid container spacing={3}>
          {WhyChooseData.map((item, index) => (
            <Grid key={index} item xs={12} md={4}>
              <ChooseTile title={item.title} icon={item.icon} />
            </Grid>
          ))}          
        </Grid>
      </MuiContainer>
    </ChooseSection>
  );
};
export default WhyChooseUs;

const ChooseSection = styled.section`
  ${({ theme }) => `
    margin-top: -120px;
    position: relative;
    margin-bottom: ${theme.spacing(5)};

    @media (max-width: 900px) {
      margin-top: 0px;
      padding: ${theme.spacing(5, 0, 0)};
      margin-bottom: ${theme.spacing(2.5)};
     }
  `}
`;
