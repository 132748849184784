import * as React from "react";
import styled from "@emotion/styled";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, Typography, Slide, IconButton } from "@mui/material";  // Add DialogTitle here
import { AddCircle, EastOutlined } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ServiceTile = ({ title, para, modalData }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Item>
      <Typography variant="h5">{title}</Typography>
      <Typography> {para}</Typography>
      <BottomAction onClick={handleClickOpen}>
        <IconButton variant="outlined"><EastOutlined /></IconButton>
        <IconButton>Read More <AddCircle /></IconButton>
      </BottomAction>
      <MuiDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{modalData.title}</DialogTitle>  {/* Now DialogTitle is defined */}
        <DialogContent>
          <Typography>
            <strong>Description:</strong> {modalData.description}
          </Typography>
          <Typography component="h6" variant="body2">
            <strong>{modalData.keyFeaturesTitle}</strong>
          </Typography>
          <List>
            {modalData.keyFeatures.map((feature, index) => (
              <ListItem key={index}>{feature}</ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions justify-content="center">
          <Button variant="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </MuiDialog>
    </Item>
  );
};

export default ServiceTile;

const Item = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.palette.blue};
    text-align: center;
    padding: ${theme.spacing(7, 4, 12)};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    align-items: center;
    justify-content: space-between;
    box-shadow: -5px -5px 20px rgba(0, 0, 0, 0.20);
    border-radius: 10px;
    position: relative;
    min-height: 300px;
    height: 100%;

    h5 {
      min-height: 60px;
    }

    @media (max-width: 900px) {
      min-height: 200px;
      padding: ${theme.spacing(8, 4, 10)};

      h5 {
        font-size: 20px;
        min-height: auto;
      }
    }

    p {
      flex: 1;
    }

    p, h5 {
      color: ${theme.colors.palette.white};
      margin: 0;
    }    
  `}
`;

const BottomAction = styled.div`
  ${({ theme }) => `
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    width: 100%;

    .MuiButtonBase-root {
      font-size: 15px;
      color: ${theme.colors.palette.white};
      gap: 10px;
      display: inline-flex;
      font-family: poppins;
    }
  `}
`;

const MuiDialog = styled(Dialog)`
  ${({ theme }) => `
    &.MuiDialog-root {
      .MuiDialogTitle-root {
        color: ${theme.colors.palette.blue};
        font-size: 20px;
        text-align: center;
        font-weight: 700;
        margin-top: ${theme.spacing(2)};          
      }
      .MuiDialogContent-root {
        padding: ${theme.spacing(2, 5, 5)};
      }
      p { 
          font-size: 12px;
          color: ${theme.colors.palette.black};
      }
      .MuiList-root { 
        display: flex;
        flex-wrap: wrap;
        font-family: poppins;
        list-style: disc;

        li {
          max-width: 50%;
          flex: 0 0 50%;
          font-size: 12px;
          color: ${theme.colors.palette.black};
          &::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: ${theme.colors.palette.black};
            position: absolute;
            left: 0;
          }
        }
      }
      .MuiDialogActions-root  {
        justify-content: center;
        padding-bottom: ${theme.spacing(4)};
      }
    }
  `}
`;
