import * as React from "react";
import styled from "@emotion/styled";
import { IconButton, Link, Typography } from "@mui/material";


const ContactSidebar = ({ open , setOpen }) => {
  return (
    <FormContainer className={open ? "open" : ""}>
      <IconButton onClick={() => setOpen(false)}><img src="/images/close.svg" width="36" height="35" /></IconButton>
      <img className="logo" src="/images/logo-2.png" width={182} height={85} alt="Logo PivotalHubConsulting" />
      <Typography component="h4"><strong>Contact info</strong></Typography>
      <Typography className="description">Mon - Fri : 8:00 - 16:00<br />
        <strong>Email</strong> - <Link href="mailto:General@PivotalHubConsulting.com">General@PivotalHubConsulting.com</Link></Typography>
      <Typography component="h4"><strong>office address</strong></Typography>
      <Typography className="description"><strong>Angola - </strong> Avenida Comandante Gika, Travessa dos
        Militares Nº 10,  Maianga - Luanda</Typography>
      <Typography className="copyright">Copyright © pivotalhubconsulting . All Rights Reserved.</Typography>
    </FormContainer>
  );
};
export default ContactSidebar;

const FormContainer = styled.section`
  ${({ theme }) => `
    position: relative;
    padding: ${theme.spacing(3)};
    background-color: ${theme.colors.palette.mediumBlue};
    border-radius: 6px;
    max-width: 385px;
    width: 100%;
    position: fixed;
    height: 488px;
    right: 0;
    text-align: center;
    transition: all 0.5s ease-in-out;
    transform: translateX(100%);
    z-index: 9999;

    &.open {
      transform: translateX(0);
    }

    .logo {
      margin: ${theme.spacing(7.5, 0, 4)};
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 7px;
      right: 7px;
      border: 1px solid ${theme.colors.palette.white};
      top: 7px;
      left: 7px;
      z-index: -1;
    }

    .description {
      font-size: 13px;
    }

    .copyright {
      font-size: 12px;
    }

    .MuiIconButton-root {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    .MuiTypography-root {
      color: ${theme.colors.palette.white};
    }

    h4 {
      margin-bottom: ${theme.spacing(1.25)};
      text-transform: capitalize;
    }
  `}
`;