const colors = {
    palette: {
       black: "#000000",
       blue: "#2E3178",
       lightBlue: "#494C9F",
       mediumBlue: "#4247BC",
       darkBlue: "#041543",
       white: "#FFFFFF",
       green: "#4CAF50",
       red: "#F44336"
    }
}

export default colors;