const container = {
   styleOverride: {
      root: {
         maxWidth: "1200px"
      },

      maxWidthXl: {
        maxWidth: "1200px"
      }
   }
}

export default container;