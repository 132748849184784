import * as React from "react";
import styled from "@emotion/styled";
import { Button, Grid, Typography } from "@mui/material";
import MuiContainer from "./MuiContainer";
import Heading from "./Heading";
import MobileTitle from "./MobileTitle";
import { useTranslation } from 'react-i18next';

// Define the AboutUs component
const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Section id="about-us">
      <MuiContainer>
        <MobileTitle title={t('companyOverview.title')} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="content">
            <Heading subtitle={t('companyOverview.title')} hidesm={true} title={t('companyOverview.subHeading')} align="left" />
            <MobileTitle title={t('companyOverview.subHeading')} align="left" />
            <Typography>{t('companyOverview.intro')}</Typography>
            <Typography>{t('companyOverview.mission')}</Typography>
            <Typography variant="h5">{t('companyOverview.objectiveTitle')}</Typography>
            <Typography>{t('companyOverview.objective')}</Typography>
            {/* <Button variant="outlined">Contact</Button> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <ImageContainer>
              <img src="/images/about-us.jpg" alt="About Us" width="644" height="496" />
            </ImageContainer>
          </Grid>
        </Grid>
      </MuiContainer>
    </Section>
  );
};

export default AboutUs;

// Styled components
const Section = styled.section`
  ${({ theme }) => `
    position: relative;
    padding: ${theme.spacing(5, 0)};    

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .MuiButtonBase-root {
        margin-top: auto;
      }
    }

    .MuiGrid-item {
      display: flex;
    }

    @media (max-width: 900px) {
      padding: ${theme.spacing(2.5, 0)};    

      .MuiGrid-container {
        flex-direction: column-reverse; 
      }
    }

    h5 {
      margin-bottom: ${theme.spacing(2)};
      color: ${theme.palette.primary.main}; // Adjust based on your theme
    }

    h2 {
      margin-bottom: ${theme.spacing(4)};
      color: ${theme.palette.primary.main}; // Adjust based on your theme
    }
  `}
`;

const ImageContainer = styled.div`
  ${({ theme }) => `
    position: relative;
    display: flex;
    padding: ${theme.spacing(3.5, 3.5, 0, 0)};
    width: 100%;      

    img {
      width: 100%;
      border-radius: 3px;
      height: auto;
    }

    @media (max-width: 900px) {
      padding: ${theme.spacing(2, 2, 0, 0)};

      img {
        max-width: inherit;
      }
    }

    &::after {
      width: calc(100% - 28px);
      height: calc(100% - 28px);
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      background-color: ${theme.palette.primary.main}; // Adjust based on your theme
      border-radius: 3px;
      z-index: -1;
    }
  `}
`;
