import * as React from 'react';
import { Box, Grid, Stack, Typography} from '@mui/material';
import styled from '@emotion/styled';
import MuiContainer from '../MuiContainer';
import Logo from '../Logo';
import Socials from '../Socials';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  return (
    <>
    <FooterWrapper>
       <MuiContainer>
          <Grid container spacing={2} justifyContent="space-between">
             <Grid item xs={12} md={6} className='left-section'>
               <Logo/>
                <Typography variant='h5'>{t('footer.contactText')}</Typography>                
                 <Socials hidesm={true}/>
              </Grid>
              <Grid item xs={12} md={4} className='right-section'>
              <Typography variant="h5" component="h3"><strong>{t('footer.ourAddressTitle')}</strong></Typography>
               <Typography><strong>Angola  - </strong> Avenida Comandante Gika ,Travessa dos
               Militares Nº 10,  Maianga - Luanda</Typography>

               <Box display="" alignItems="center">
                  <Typography variant="body1">Our office is situated in</Typography>
                  <Box
                    component="span"
                    sx={{ marginLeft: 1 }}
                  >
                    <img
                      src="/images/uk.png"
                      alt="UK Flag"
                      style={{ width:60, height: 50, verticalAlign: 'middle' }}
                    />
                  </Box>
                  <Box
                    component="span"
                    sx={{ marginLeft: 1 }}
                  >
                    <img
                      src="/images/pt.png"
                      alt="PT Flag"
                      style={{ width: 60, height: 50, verticalAlign: 'middle' }}
                    />
                  </Box>
                </Box>

               <Saparate>
               <img src="/images/maps.jpg" width="200" height="150"/>
               <Socials/>
               </Saparate>
              </Grid>
          </Grid>
       </MuiContainer>       
    </FooterWrapper>
    <Copyright><Typography variant='h6' component="p">Copyright © pivotalhubconsulting . All Rights Reserved.</Typography></Copyright>
    </>
  );
}
export default Footer;

const FooterWrapper = styled.footer`
  ${({theme}) => `
   background-color: ${theme.colors.palette.darkBlue};
   padding: ${theme.spacing(4,0)};

   .MuiTypography-root, a {
     color: ${theme.colors.palette.white};     
   }

   h3, h5 {
    margin-bottom: ${theme.spacing(2.5)};
   }

   @media (max-width: 900px) {
     h3 {
      margin-bottom: ${theme.spacing(0.5)};
      font-size: 20px;
      margin-top: ${theme.spacing(1.25)};
    }
}

   .right-section {
     p {
       font-size: 13px;
     }

    > div > img {
       width: 100%;
       max-width: 300px;
       height: 200px;
       border-radius: 10px;
       margin-top: ${theme.spacing(1.25)};
     }
   }
  `}
`

const Saparate = styled.div`
  ${({theme}) => `
    padding: ${theme.spacing(2,0,3)};
     flex-direction: column;
        gap: 20px;
        align-items: center;
        
      @media (max-width: 900px) {
        display: flex;
         justify-content: center;
         text-align: center;
     }

    > div {
      display: none;  
      justify-content: center;

      @media (max-width: 900px) {
        display: flex;         
     }
    }    
  `}
`;

const Copyright = styled.div`
  ${({ theme }) => `
    text-align: center;
    background-color: ${theme.colors.palette.blue};
    padding: ${theme.spacing( 1.875,0)}; 

    .MuiTypography-root, a {
     color: ${theme.colors.palette.white};   
   }
  `}
`;
