import * as React from "react";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";

const ChooseTile = ({title, icon}) => {
  return (
    <Item>
      <img src={icon} width="50" height="30" />
      <Typography>{title}</Typography>
      {/* <Button variant="outlined">Contact</Button> */}
    </Item>
  );
};
export default ChooseTile;

const Item = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.palette.white};
    text-align: center;
    padding: ${theme.spacing(4)};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(3)};
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 100%;

    @media (max-width: 900px) {
      gap: ${theme.spacing(2)}; 
    }

    p {
      color: ${theme.colors.palette.blue};
      font-weight: 500;
      margin: 0 auto;
      max-width: 290px;

    }

    img {
      transition: 0.3s ease all;
      width: auto;
    }
  `}
`;
