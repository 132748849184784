import * as React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";

const MuiContainer = ({ children }) => {
  return <CustomContainer>{children}</CustomContainer>;
};
export default MuiContainer;

const CustomContainer = styled(Container)`
  &.MuiContainer-root {
    max-width: 1400px;
  }
`;
