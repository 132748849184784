import * as React from "react";
import styled from "@emotion/styled";
import axios from "axios";
import {
  Button,
  InputAdornment,
  MenuItem,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";

const ContactForm = () => {
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    service: "",
    message: "",
  });

  const [formErrors, setFormErrors] = React.useState({
    name: false,
    email: false,
    service: false,
    message: false,
  });

  const [loading, setLoading] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState("");
  const [responseStatus, setResponseStatus] = React.useState(""); // 'success' or 'error'

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const errors = {
      name: formData.name === "",
      email: formData.email === "",
      service: formData.service === "",
      message: formData.message === "",
    };
  
    setFormErrors(errors);
  
    // Check if any errors exist
    const hasErrors = Object.values(errors).some((error) => error);
  
    if (!hasErrors) {
      setLoading(true);
      setResponseMessage(""); // Clear previous messages
      try {
        // Send the form data to your API endpoint
        const response = await axios.post(
          "https://pivotalhub.vercel.app/api/contact",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        // Handle the response
        if (response.data.success) {
          setResponseMessage("Thanks for contacting us. We will connect with you soon.");
          setResponseStatus("success");
          // Clear the form after successful submission

          console.log(response.data);
          setFormData({
            name: "",
            email: "",
            service: "",
            message: "",
          });
        } else {
          setResponseMessage("Failed to send your message. Please try again later.");
          setResponseStatus("error");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setResponseMessage("There was an error sending your message.");
        setResponseStatus("error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <FormContainer>
      <TextField
        name="name"
        label="Your Name"
        variant="filled"
        fullWidth
        value={formData.name}
        onChange={handleChange}
        error={formErrors.name}
        helperText={formErrors.name && "Name is required"}
      />
      <TextField
        name="email"
        label="Your Email"
        variant="filled"
        fullWidth
        value={formData.email}
        onChange={handleChange}
        error={formErrors.email}
        helperText={formErrors.email && "Email is required"}
      />
      <TextField
        name="service"
        id="outlined-select-currency"
        select
        fullWidth
        label="Select a Service"
        value={formData.service}
        onChange={handleChange}
        variant="filled"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <KeyboardArrowDownOutlined />
            </InputAdornment>
          ),
        }}
        error={formErrors.service}
        helperText={formErrors.service && "Service selection is required"}
      >
        <MenuItem value="Strategic Planning">Strategic Planning</MenuItem>
        <MenuItem value="Operational Efficiency/Project Management">
          Operational Efficiency/Project Management
        </MenuItem>
        <MenuItem value="Financial Management/Accounting">
          Financial Management/Accounting
        </MenuItem>
      </TextField>
      <TextField
        name="message"
        id="standard-multiline-static"
        label="Message"
        multiline
        fullWidth
        rows={3}
        variant="filled"
        value={formData.message}
        onChange={handleChange}
        error={formErrors.message}
        helperText={formErrors.message && "Message is required"}
      />
      <Button
        variant="contained"
        fullWidth
        onClick={handleSubmit}
        disabled={loading}
        startIcon={loading && <CircularProgress size={24} color="inherit" />}
      >
        {loading ? "Sending..." : "Send"}
      </Button>
      {responseMessage && (
        <ResponseMessage status={responseStatus}>
          {responseMessage}
        </ResponseMessage>
      )}
    </FormContainer>
  );
};

export default ContactForm;

const FormContainer = styled.section`
  ${({ theme }) => `
    position: relative;
    padding: ${theme.spacing(7)};
    background-color: ${theme.colors.palette.blue};
    border-radius: 10px;
    max-width: 575px;
    margin-left: auto;

    @media (max-width: 900px) {
     padding: ${theme.spacing(4.5)};
     margin: 0 auto;
    }

    .MuiButton-root {
      box-shadow: none;
      height: 65px;
      border: 1px solid ${theme.colors.palette.white};
      background: ${theme.colors.palette.darkBlue};
      color: ${theme.colors.palette.white};
      font-weight: bold;
      font-size: 1rem;

      @media (max-width: 900px) {
        height: 48px;
      }

      .MuiCircularProgress-root {
        margin-right: ${theme.spacing(1)};
      }
    }

    .MuiFormControl-root {
     margin-bottom: ${theme.spacing(2.5)};
     border-radius: 10px;

     @media (max-width: 900px) {
         margin-bottom: ${theme.spacing(1.25)};
    }

     .MuiFilledInput-root {
       line-height: 2rem;
       padding-left: 22px;

       @media (max-width: 900px) {
        &:not(.MuiInputBase-multiline)  {
         height: 48px;
        }
       }

       &::after {
         display: none;
       }
     }

     .MuiSelect-icon {
      display: none;
     }

     .MuiFormLabel-root {
       transform: translate(21px, 22px) scale(1);

       @media (max-width: 900px) {
          transform: translate(21px, 15px) scale(1);
         }

       &.MuiInputLabel-shrink { 
         transform: translate(21px, 2px) scale(0.8);
       }
     }
    }
    .MuiFilledInput-root   {
      background: ${theme.colors.palette.white};
      border-radius: 10px;

      input {
        height: 2rem;
        padding: 16px 16px 16px 0;

        @media (max-width: 900px) {
          height: 1.5rem;
          padding: 12px 16px 12px 0;
         }
      }

      &:hover, &.Mui-focused {
        background: ${theme.colors.palette.white};
    }
  `}
`;

const ResponseMessage = styled(Typography)`
  ${({ status, theme }) => `
    margin-top: ${theme.spacing(2)};
    color: ${
      status === "success"
       ? `${theme.colors.palette.green} !important`
        : `${theme.colors.palette.red} !important`
    };
    font-weight: bold;
    text-align: center;
  `}
`;
