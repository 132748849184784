export const MenuItems = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "About Us",
    link: "#about-us",
  },
  {
    label: "Services",
    link: "/services",
  },
  {
    label: "Faq",
    link: "/services",
  },
  {
    label: "Contact Us",
    link: "/contact",
  },
];

export const MenuItemsMobile = [
  {
    label: "Home",
    link: "/",
    icon: "/images/home.svg"
  },
  {
    label: "Services",
    link: "#services",
    icon: "/images/setting-icon.svg"
  }, 
  {
    label: "About Us",
    link: "#about-us",
    icon: "images/user.svg"
  },
  
  {
    label: "Contact Us",
    link: "#contact-us",
     icon: "/images/call-icon.svg"
  },
];


export const WhyChooseData = [
  {
    title: "Strategic Planning",
    icon: "/images/choose-icon1.svg"
  },
  {
    title: "Operational Efficiency & Project Management",
    icon: "/images/choose-icon2.svg"
  },
  {
    title: "Financial Management & Accounting ",
    icon: "/images/choose-icon3.svg"
  }
];

export const ServiceData = [
  {
    title: "Strategic Planning",
    para: "Crafting actionable business plans aligned with your goals and objectives.",
    modalData: {
       title: "Strategic Planning",
       description: "Crafting actionable business plans aligned with your goals and objectives.",
       features: [
         {
          featureItem: "Comprehensive",
         },
         {
          featureItem: "Vision and Goal Setting",
         },
         {
          featureItem: "Analysis",
         },
         {
          featureItem: "Actionable Roadmap",
         },
         {
          featureItem: "VisionRisk Management",
         },
         {
          featureItem: "VisionPerformance Monitoring",
         }
       ],
    },
  },
  {
    title: "Operational Efficiency & Project Management",
    para: "Streamlining processes and workflows to maximise productivity minimise costs and maximise profits."
  },
  {
    title: "Financial Management & Accounting ",
    para: "Implementing effective financial strategies to optimise revenue generation and control expenses."
  }
];