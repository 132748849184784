import * as React from "react";
import styled from "@emotion/styled";
import MuiContainer from "./MuiContainer";
import Heading from "./Heading";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

const Faq = () => {
  const { t } = useTranslation();

  const faqData = t('faq.questions', { returnObjects: true });

  if (!Array.isArray(faqData)) {
    console.error('Expected faqData to be an array', faqData);
    return null;
  }

  return (
    <Section id="faq">
      <MuiContainer>
        <Heading title={t('faq.title')} />
        {faqData.map((item, index) => (
          <Accordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              {item.question}
            </AccordionSummary>
            <AccordionDetails>
              {item.answer}
            </AccordionDetails>
          </Accordion>
        ))}
      </MuiContainer>
    </Section>
  );
};
export default Faq;

const Section = styled.section`
  ${({ theme }) => `
    position: relative;
    padding: ${theme.spacing(5, 0)};

    @media (max-width: 900px) {
      padding: ${theme.spacing(2.5, 0)};
    }

    .MuiPaper-root {
      box-shadow: none;
      border: 1px solid ${theme.colors.palette.black};
      border-radius: 10px !important;
      overflow: hidden;
      margin-bottom: ${theme.spacing(5)};

      @media (max-width: 900px) {
        margin-bottom: ${theme.spacing(2.5)};
      }

      .MuiAccordionSummary-root {
        font-size: 20px;
        color: ${theme.colors.palette.black};
        padding: ${theme.spacing(0, 5)};
        height: 80px;
        font-weight: 500;
        border-radius: 10px;

        @media (max-width: 1200px) {
          font-size: 16px;
          height: 60px;
          padding: ${theme.spacing(0, 3)};
        }

        .MuiAccordionSummary-expandIconWrapper {
          color: ${theme.colors.palette.black};
        }
      }

      .MuiCollapse-root {
        .MuiAccordionDetails-root {
          padding: ${theme.spacing(3, 5)};
          background: #EEEEEF;
          border-radius:10px;

          @media (max-width: 900px) {
           padding: ${theme.spacing(2, 3)};
          }
        }
      }
    }

    h2 { 
     margin-bottom: ${theme.spacing(4)};
     color: ${theme.colors.palette.blue}
    }
  `}
`;



