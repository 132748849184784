import * as React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const Heading = ({align, subtitle, title, hidesm}) => {
  return (
    <Header align={align} hidesm={hidesm}>      
       {subtitle && <Typography variant="h5">{subtitle}</Typography>}
       <Typography variant="h2">{title}</Typography>
    </Header>
  );
};
export default Heading;

const Header = styled.div`
  ${({ theme, align, hidesm }) => `
   text-align: ${align ? [align] : "center"};
   width: 100%;
   display: block;

  @media (max-width: 900px) {
    text-align: center;
    display: ${hidesm ? "none" : "block"};
  }

   h5 {
     margin-bottom: ${theme.spacing(1)};
   }

   h2 {
     margin-bottom: ${theme.spacing(5)};
     color: ${theme.colors.palette.blue};

     @media (max-width: 900px) {
        margin-bottom: ${theme.spacing(3.5)};
        
     }
    }
  `}
`;
