import * as React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const MobileTitle = ({title, align}) => {
  return (
    <Title variant="h2" align={align}>{title}</Title>
  );
};
export default MobileTitle;

const Title = styled(Typography)`
  ${({ theme, align }) => `
    &.MuiTypography-root {
      display: none;       

      @media (max-width: 900px) {
        display: block;
        color: ${theme.colors.palette.blue};
        margin-bottom: ${theme.spacing(3.75)};
        text-align: ${align ? [align] : "center"};
       }
     }
  `}
`;
