import * as React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

const Banner = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <img src="/images/banner.jpg" alt="Banner" width="1440" height="750" />
      <Caption>
        <Typography variant="h1">{t('bannerHeading.heading')}</Typography>
        <Typography variant="h5" component="p">{t('bannerHeading.subheading')} </Typography>
      </Caption>
    </Container>
  );
};

export default Banner;


const Container = styled.div`
  display: flex;
  position: relative;

  > img {
    width: 100%;
    object-fit: cover;
    min-height: 600px;

    @media (max-width: 900px) {
        min-height: 350px;
        height: auto; 
     }
  }
`;

const Caption = styled.div`
  ${({theme}) => `
    max-width: 1400px;
    padding: 0 24px;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% + 50px);
    transform: translateY(-50%);
    margin: 0 auto;
    z-index: 2;

     @media (max-width: 900px) {
       top: calc(50% + 30px);
     }

    .MuiTypography-root {
      color: ${theme.colors.palette.white};
      max-width: 850px;
    }

    h1 {
      margin-bottom: ${theme.spacing(5)};

      @media (max-width: 900px) {
        margin-bottom: ${theme.spacing(2)};
      }
    }
    
    p {
      letter-spacing: 2px;
      line-height: 2;

      @media (max-width: 900px) {
        font-size: 14px;
        letter-spacing: normal;
        line-height: 1.5;
      }
    }
  `}
`;
