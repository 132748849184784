// src/App.js

import React from 'react';
import { Helmet } from 'react-helmet'; // For managing the document head
import Layout from './components/common/Layout';
import Banner from './components/Banner';
import WhyChooseUs from './components/WhyChooseUs';
import AboutUs from './components/AboutUs';
import OurService from './components/OurService';
import RequestQuote from './components/RequestQuote';
import Testimonials from './components/Testimonials';
import Faq from './components/Faq';


function App() {
  return (
    <>
      <Helmet>
        <title>Pivotal Hub Consulting</title>
        <meta name="description" content="Generated by create react app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,600&display=swap" rel="stylesheet"/>
      </Helmet>
      <div className="poppins">
        <Layout>
          <Banner />
          <WhyChooseUs />
          <AboutUs />
          <OurService />
          <Faq />
          <Testimonials />
          <RequestQuote />
        </Layout>
      </div>
    </>
  );
}

export default App;
