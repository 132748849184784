import colors from "./colors";

const palette = {
  primary: {
    main: colors.palette.blue
  },
  secondary: {
    main:  colors.palette.darkBlue
  }
};

export default palette;
