import * as React from 'react';
import styled from '@emotion/styled';
import { Link, Stack } from '@mui/material';

const Socials = ({hidesm}) => {
  return (
    <SocialIcons direction="row" spacing={2.5} alignItems="center" hidesm={hidesm}>
      <Link href="https://www.facebook.com/profile.php?id=61561845817227"><img src="/images/facebook.svg" width="6" height="12"/></Link>
      <Link href="/"><img src="/images/instagram.svg" width="10" height="10"/></Link>
      <Link href="/"><img src="/images/twitter.svg" width="10" height="9"/></Link>
      <Link href="/"><img src="/images/youtube.svg" width="12" height="8"/></Link>
      <Link href="https://www.linkedin.com/in/pivotal-hub-63155931a"><img src="/images/linkedin.svg" width="10" height="9"/></Link>
   </SocialIcons>
  );
}
export default Socials;

const SocialIcons = styled(Stack)`
  ${({theme, hidesm}) => `
    @media (max-width: 900px) {
      display: ${hidesm ? "none" : "flex"};
    }
    a {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid ${theme.colors.palette.white};
      display: flex;
      align-items: center;  
      justify-content: center;

      img {
        height: 12px;
        width: 12px;
      }
    }
  `}
`; 
