const fontFamily = "'Poppins', 'sans-serif'";

const typography = {    
    root: {
        fontFamily: fontFamily,
        fontSize: '1rem',
        lineHeight: 1.5,
        
    },
    h1: {
        fontSize: '72px',
        lineHeight: 1.2,
        fontFamily: fontFamily,
        fontWeight: 700,

        "@media (max-width: 1199px)": {
            fontSize: '54px',
        },

        "@media (max-width: 900px)": {
            fontSize: '30px',
        }
    },

    h2: {
        fontSize: '36px',
        fontFamily: fontFamily,
        fontWeight: 700,

        "@media (max-width: 1199px)": {
            fontSize: '32px',
        },

        "@media (max-width: 900px)": {
            fontSize: '28px',
        }
    },

    h3: {
        fontSize: '32px',
        fontFamily: fontFamily,
        fontWeight: 500,

        "@media (max-width: 1199px)": {
            fontSize: '22px',
        }
    },

    h4: {
        fontSize: '24px',
        fontFamily: fontFamily,
        fontWeight: 500,

        "@media (max-width: 1199px)": {
            fontSize: '18px',
        }
    },

    h5: {
        fontSize: '20px',
        fontFamily: fontFamily,
        fontWeight: 500,
        lineHeight: 1.5,

        "@media (max-width: 1199px)": {
            fontSize: '14px',
        }
    },

    h6: {
        fontSize: '18px',
        fontFamily: fontFamily,
        fontWeight: 500,

        "@media (max-width: 1199px)": {
            fontSize: '12px',
        }
    },

    body2: {
        fontSize: '14px',
        fontFamily: fontFamily,
        fontWeight: 500,
    },

    body1: {
        fontSize: '16px',
        fontFamily: fontFamily,
        fontWeight: 400,
        marginBottom: '1.25rem',

        "&:last-child": {
            marginBottom: 0,
        },

        "@media (max-width: 900px)": {
            fontSize: '14px',
            marginBottom: '1rem',
        }

    }
    
}

export default typography;
