import * as React from "react";
import styled from "@emotion/styled";
import { IconButton, List, ListItem, Typography } from "@mui/material";
import Socials from "./Socials";
import { Link } from '@mui/material';
import { MenuItemsMobile } from "../utils/constant";
import { Image } from "@mui/icons-material";

const MenuSideBar = ({ open, setOpen }) => {
  return (
    <Container className={open ? "open" : ""}>
      <IconButton onClick={() => setOpen(false)}>
        <img src="/images/close-round.svg" width="36" height="35" />
      </IconButton>
      <List>
        {MenuItemsMobile.map((item, index) => (
          <ListItem key={index}>
            <Link href={item.link}><Icon>
              <img src={item.icon} width="21" height="21" />
            </Icon> <Typography component="span">{item.label}</Typography></Link>
          </ListItem>
        ))}        
      </List>
      <Socials />
    </Container>
  );
};
export default MenuSideBar;

const Container = styled.section`
  ${({ theme }) => `
    position: relative;
    padding: ${theme.spacing(3)};
    background-color: ${theme.colors.palette.mediumBlue};
    border-radius: 6px;
    max-width: 310px;
    width: 100%;
    position: fixed;
    bottom: 0;  
    top: 0;
    right: 0;
    text-align: center;
    transition: all 0.5s ease-in-out;
    transform: translateX(100%);
    z-index: 9999;

    > div {
      justify-content: center;
    }

    .MuiIconButton-root {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    ul {
      margin-top: ${theme.spacing(6)};

      li {
        background-color: ${theme.colors.palette.white};
        margin-bottom: ${theme.spacing(2.5)};
        padding: ${theme.spacing(1.25, 2, 1.25, 4)};
        border-radius: 5px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 116px;
          background-color: ${theme.colors.palette.blue};
          z-index: -1;
          clip-path: polygon(83% 0, 0 0, 0 100%, 83% 100%, 100% 48%);
        }

        a {
          text-transform: capitalize;
          color: ${theme.colors.palette.darkBlue};
          font-weight: 500;
          display: flex;
          align-items: center;
          width: 100%;
          text-decoration: none;

          span {
            flex: 1;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }

    &.open {
      transform: translateX(0);
    }    
  `}
`;

const Icon = styled.div`
  ${({ theme }) => `
   margin-right: ${theme.spacing(8)};
   display: flex;
 `}
`;
