import * as React from "react";
import styled from "@emotion/styled";
import {  Button, Grid, Stack, Typography } from "@mui/material";
import MuiContainer from "./MuiContainer";
import Heading from "./Heading";
import ContactForm from "./ContactForm";
import { useTranslation } from 'react-i18next';

const RequestQuote = () => {
  const { t } = useTranslation();
  return (
    <Section id="contact-us">
      <MuiContainer>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="content">
          <Heading subtitle={t('requestQuote.subtitle')} title={t('requestQuote.title')} align="left" />
          <Typography>{t('requestQuote.description')}</Typography>
           <Typography variant="h3"><strong>{t('requestQuote.askcall')}</strong></Typography>
           <Typography variant="h5" component="div"><Stack direction="row" alignItems="center" gap={2}><img src="/images/call.svg" width="24" height="25" />+244 927 050 408 </Stack></Typography>
          </Grid>          
          <Grid item xs={12} md={6}>
            <ContactForm/>
          </Grid>
        </Grid>
      </MuiContainer>
    </Section>
  );
};
export default RequestQuote;

const Section = styled.section`
  ${({ theme }) => `
    position: relative;
    padding: ${theme.spacing(10, 0)};    

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .MuiButtonBase-root {
        margin-top: auto
      }
    }

    @media (max-width: 900px) {
     padding: ${theme.spacing(5, 0)};
     text-align: center;

     .content {
      align-items: center;
     }
    }

    p, div{
     color: ${theme.colors.palette.blue}
    }

    h2 {
     margin-bottom: ${theme.spacing(4)};
     color: ${theme.colors.palette.blue}
    }

    h3 {
     margin-bottom: ${theme.spacing(2 )};
     color: ${theme.colors.palette.blue}
    }
  `}
`;

const ImageContainer = styled.div` 
   ${({ theme }) => `
     position: relative;
     display: flex;
     padding: ${theme.spacing(3.5, 3.5, 0, 0)};

     img {
       width: 100%;
       border-radius: 3px;
       height: auto;
     }

     &::after { 
        width: calc(100% - 28px);
        height: calc(100% - 28px);
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        background-color: ${theme.colors.palette.blue};
        border-radius: 3px;
        z-index: -1;
     }
   `}
`;

