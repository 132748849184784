import * as React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import MuiContainer from "./MuiContainer";
import { useTranslation } from 'react-i18next';

const Testimonials = () => {
  const { t } = useTranslation();
  
  const testimonialData = t('testimonials.content', { returnObjects: true });

  if (!Array.isArray(testimonialData)) {
    console.error('Expected testimonialData to be an array', testimonialData);
    return null;
  }

  return (
    <Section>
      <img src="/images/testimonials.jpg" alt="Testimonials" />
        <MuiContainer>
          <Inner>
          <Typography variant="h2">{t('testimonials.title')}</Typography>
          {testimonialData.map((item, index) => (
            <React.Fragment key={index}>
              <Typography variant="h4" component="p">
                {item.text}
              </Typography>
              <Typography variant="h4" component="p" className="client-name">
                <strong>{item.client}</strong>
              </Typography>
            </React.Fragment>
          ))}
          </Inner>
        </MuiContainer>
    </Section>
  );
};

export default Testimonials;

const Overlay = styled.div`
   
`;

const Section = styled.section`
  ${({ theme }) => `
    position: relative;
    display: flex;
    padding: ${theme.spacing(9, 0)};  

    &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);   
    }
    
    @media (max-width: 900px) {
      padding: ${theme.spacing(2.5, 0)};  

      h2 {
      font-size: 24px;
       margin-bottom: ${theme.spacing(2)} !important;
       }
    }   

    > img {
     object-fit: cover;
     width: 100%;
     position: absolute;
     top: 0;
     height: 100%;
    }

    h2 {
     margin-bottom: ${theme.spacing(4)};
     color: ${theme.colors.palette.white}
    }
  `}
`;

const Inner = styled.div`
  ${({ theme }) => `
     padding: ${theme.spacing(6)};
     border: 1px solid ${theme.colors.palette.white};
     text-align: center;
     position: relative;
     border-radius: 5px;     

     p {
       color: ${theme.colors.palette.white};
       font-weight: 400;
       line-height: 2;

       &.client-name {
         margin-top: ${theme.spacing(7)};
        }       
     }

      @media (max-width: 900px) {
       padding: ${theme.spacing(3)};
      p {
          font-size: 14px;
          line-height: 1.5;
           &.client-name {
         margin-top: ${theme.spacing(2)};
        } 
      }            
      }
   `}
`;
