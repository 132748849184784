import * as React from "react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import MuiContainer from "./MuiContainer";
import Heading from "./Heading";
import ServiceTile from "./tile/ServiceTile";
import MobileTitle from "./MobileTitle";
import { useTranslation } from 'react-i18next';

const OurService = () => {
  const { t } = useTranslation();

  // Fetch services data from translations
  const services = t('services.data', { returnObjects: true });

  if (!Array.isArray(services)) {
    console.error('Expected services to be an array', services);
    return null;
  }

  return (
    <Section id="services">
      <MuiContainer>
        <MobileTitle title={t('services.title')} />
        <Heading hidesm={true} subtitle={t('services.title')} title={t('services.subtitle')} />
        <Grid container spacing={3}>
          {services.map((item, index) => (
            <Grid key={index} item xs={12} md={4}>
              <ServiceTile 
                title={item.title} 
                para={item.description} 
                modalData={item.modalData} 
              />
            </Grid>
          ))}
        </Grid>
      </MuiContainer>
    </Section>
  );
};

export default OurService;

const Section = styled.section`
  ${({ theme }) => `
    position: relative;
    padding: ${theme.spacing(5, 0)};
    
    @media (max-width: 900px) {
      padding: ${theme.spacing(2.5, 0)};
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .MuiButtonBase-root {
        margin-top: auto;
      }
    }

    h2 {
      margin-bottom: ${theme.spacing(4)};
      color: ${theme.colors.palette.blue};
    }
  `}
`;
