import * as React from 'react';
import styled from '@emotion/styled';
import { Link } from '@mui/material';

const Logo = ({sticky}) => {
  return (
    <LogoContainer sticky={sticky}><Link href="/"><img src="/images/logo.png" width="182" height="106"/></Link></LogoContainer>
  );
}
export default Logo;

const LogoContainer = styled.div`
  ${({sticky}) => `
     img {
        transition: 0.3s ease all;
        width: ${sticky ? "130px" : "182px"};
        height: auto;

        @media (max-width: 900px) {
          width: ${sticky ? "80px" : "80px"};
        }
     }
  `}
`